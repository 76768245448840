<template>
    <information-panel
        title="状态信息"
        :loading="loading"
        :columns="columns"
        :card-bordered="true"
        :data-source="statusInfo"
        class="has-margin-top"
    />
</template>
<script>
import InformationPanel from '@components/InformationPanel'

import { empty as emptyFilter } from '@utils'

import getStatusInfoService from '@service/approal/fillingApplication/getStatusInfoService'

const columns = [
    {
        dataIndex: 'bizCodeText',
        label: '申请类型',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'applyTime',
        label: '申请时间',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'applyStateText',
        label: '申请状态',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'id',
        label: '申请批次号',
        customRender: val => emptyFilter(val)
    }
]

export default {
    name: 'StatusInfoPanel',
    components: {
        InformationPanel
    },
    props: {
        workflowApplyRecordId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            statusInfo: {},
            columns: Object.freeze(columns)
        }
    },
    watch: {
        workflowApplyRecordId: {
            handler(workflowApplyRecordId) {
                this.getStatusInfo(workflowApplyRecordId)
            },
            immediate: true
        }
    },
    methods: {
        async getStatusInfo(workflowApplyRecordId) {
            try {
                this.loading = true

                // eslint-disable-next-line
                this.statusInfo = await getStatusInfoService({ workflowApplyRecordId })
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
