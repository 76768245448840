<!-- 申请管理 - 备案申请 - 受理/详情弹框 - 申请内容 -->
<template>
    <fragment>
        <!-- 项目信息 -->
        <project-info-panel :workflow-apply-record-id="id" />

        <!-- 定额保证金信息 -->
        <fixed-deposit-panel :workflow-apply-record-id="id" />

        <!-- 状态信息 -->
        <status-info-panel :workflow-apply-record-id="id" />
    </fragment>
</template>
<script>
import Fragment from '@components/Fragment'
import ProjectInfoPanel from '@marginPaymentDetail/ProjectInfoPanel'

import StatusInfoPanel from './StatusInfoPanel'
import FixedDepositPanel from './FixedDepositPanel'

export default {
    name: 'ApplicationContent',
    components: {
        Fragment,
        StatusInfoPanel,
        ProjectInfoPanel,
        FixedDepositPanel
    },
    props: {
        record: Object,
        visible: Boolean
    },
    computed: {
        id() {
            return this.record.id
        }
    }
}
</script>
