<template>
    <information-panel
        title="定额保证金备案信息"
        :loading="loading"
        :columns="columns"
        :data-source="marginQuotaHisVo"
        class="has-margin-top"
    >
        <template #addonAfter>
            <template v-for="name in lowerLevelPanels">
                <component
                    :is="name"
                    :key="name"
                    :show-operation="false"
                    :data-source="getLowerLevelDataSource(name)"
                />
            </template>
        </template>
    </information-panel>
</template>
<script>
import InformationPanel from '@components/InformationPanel'

import {
    generateColumns,

    GUARANTEE_PANEL,
    CASH_PAYMENT_PANEL,
    DATA_SOURCE_FIELD_MAPPING,
    SHOW_CONTROL_FIELD_MAPPING
} from '@marginPaymentDetail/FixedDepositPanel/config'

import getFixedDepositInfoService from '@service/approal/fillingApplication/getFixedDepositInfoService'

const LOWER_LEVEL_PANEL_FACTORY = {
    [GUARANTEE_PANEL]: () => import('@marginPaymentDetail/FixedDepositPanel/GuaranteePanel'),
    [CASH_PAYMENT_PANEL]: () => import('@marginPaymentDetail/FixedDepositPanel/CashPaymentPanel')
}

export default {
    name: 'FixedDepositPanel',
    components: {
        InformationPanel,
        ...LOWER_LEVEL_PANEL_FACTORY
    },
    props: {
        workflowApplyRecordId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            fixedDepositInfo: {}
        }
    },
    computed: {
        // 字段配置
        columns() {
            return generateColumns(this.fixedDepositInfo)
        },
        // 基础信息数据
        marginQuotaHisVo() {
            return this.fixedDepositInfo.marginQuotaHisVO || {}
        },
        // 需要展示的二级面板组件名称集
        lowerLevelPanels() {
            return [GUARANTEE_PANEL, CASH_PAYMENT_PANEL].filter(name => this.showLowerLevelPanel(name))
        }
    },
    watch: {
        workflowApplyRecordId: {
            handler(workflowApplyRecordId) {
                this.getFixedDepositInfo(workflowApplyRecordId)
            },
            immediate: true
        }
    },
    methods: {
        async getFixedDepositInfo(workflowApplyRecordId) {
            try {
                this.loading = true

                // eslint-disable-next-line
                this.fixedDepositInfo = await getFixedDepositInfoService({ workflowApplyRecordId })
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 获取 name 指定的二级面板组件所需源数据
         *
         * @param {string} name 二级面板组件名称
         */
        getLowerLevelDataSource(name) {
            return this.fixedDepositInfo[DATA_SOURCE_FIELD_MAPPING[name]]
        },
        /**
         * 是否显示 name 指定的二级面板组件
         *
         * @param {string} name 二级面板组件名称
         */
        showLowerLevelPanel(name) {
            return this.fixedDepositInfo[SHOW_CONTROL_FIELD_MAPPING[name]]
        }
    }
}
</script>
